@import '~normalize.css';
@import '@fontsource/figtree/300.css';
@import '@fontsource/figtree/400.css';
@import '@fontsource/figtree/500.css';
@import '@fontsource/figtree/600.css';
@import '@fontsource/figtree/700.css';
@import '@fontsource/figtree/800.css';
@import '@fontsource/figtree/900.css';

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 8px;
}

body {
  height: 100%;
  font-size: 2rem;
  -webkit-font-smoothing: antialiased;
  font-family: 'Figtree', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-display: swap;
}

main {
  height: 100%;
}

a {
  all: unset;
}
